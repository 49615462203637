import { FC } from 'react';
import IconProps from './IconProps';

const AddCommentIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 0H4.8C3.52696 0 2.30606 0.505713 1.40589 1.40589C0.505713 2.30606 0 3.52696 0 4.8V12.8C0 14.073 0.505713 15.2939 1.40589 16.1941C2.30606 17.0943 3.52696 17.6 4.8 17.6H11.744L19.536 23.056C19.666 23.1478 19.8204 23.1988 19.9796 23.2024C20.1387 23.2059 20.2953 23.162 20.4293 23.0761C20.5633 22.9902 20.6686 22.8663 20.7318 22.7202C20.795 22.5741 20.8132 22.4125 20.784 22.256L20.104 17.456C21.1905 17.2477 22.1717 16.6706 22.8816 15.8222C23.5915 14.9738 23.9866 13.9062 24 12.8V4.8C24 3.52696 23.4943 2.30606 22.5941 1.40589C21.6939 0.505713 20.473 0 19.2 0ZM22.4 12.8C22.4 13.6487 22.0629 14.4626 21.4627 15.0627C20.8626 15.6629 20.0487 16 19.2 16C19.0848 15.9989 18.9707 16.0226 18.8656 16.0696C18.7604 16.1166 18.6667 16.1858 18.5907 16.2724C18.5147 16.359 18.4584 16.461 18.4255 16.5714C18.3926 16.6818 18.3839 16.7979 18.4 16.912L18.936 20.688L12.448 16.144C12.3165 16.0522 12.1604 16.002 12 16H4.8C3.95131 16 3.13737 15.6629 2.53726 15.0627C1.93714 14.4626 1.6 13.6487 1.6 12.8V4.8C1.6 3.95131 1.93714 3.13737 2.53726 2.53726C3.13737 1.93714 3.95131 1.6 4.8 1.6H19.2C20.0487 1.6 20.8626 1.93714 21.4627 2.53726C22.0629 3.13737 22.4 3.95131 22.4 4.8V12.8Z"
        fill="currentColor"
      />
      <path d="M11.1717 10.2894H7.67969V8.3153H11.1717V4.7998H13.1477V8.3153H16.6397V10.2894H13.1477V13.7598H11.1717V10.2894Z" fill="#C5A76B" />
    </svg>
  );
};

export default AddCommentIcon;
